<template>
	<div id="Index">
		<nav-header></nav-header>
		<div class="out-bj">
			<keep-alive>
				<router-view class="output" v-if="!$route.meta.keepAlive"></router-view>
			</keep-alive>
			<router-view class="output" v-if="$route.meta.keepAlive"></router-view>
		</div>
		<public-footer></public-footer>
	</div>
</template>

<script>
	import NavHeader from '@/components/common/NavHeader/NavHeader.vue'
	import PublicFooter from '@/components/common/PublicFooter/PublicFooter.vue'
	export default{
		name:'Index',
		data(){
			return{
				
			}
		},
    created(){
      // this.puo()
    },
		methods:{
      // puo(){
      //   alert ("平台维护中 客服联系QQ：3283399674");
      // }
		},
		
		components:{
			NavHeader,
			PublicFooter
		}
	}
</script>

<style lang="scss">
 #Index{
	 .out-bj{
		 width: 100%;
		//  background:#121319 url(../../assets/images/public/Index_bj.png) no-repeat center 175px;
		 background-attachment:fixed;
		 background-size: 60%;
	 }
	 
	 .el-backtop{
		 background: #4854c9;
		 i{
			 color: #fff;
		 }
	 }
     #output,.output{
          width: 14rem;
		  padding-bottom: 0.75rem;
          margin: 0 auto;
     }
 
     .Tips {
         text-align: center;
         color: #2a9fd6;
         font-size: 0.14rem;
         line-height: 0.5rem;
         cursor: pointer;
         &:hover {
             color: #2fc8ff;
        }
    }
}
</style>
