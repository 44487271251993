<template>
	<div id="SwiperBox">
		<van-notice-bar mode="closeable"  scrollable style="margin-top: -.1rem;" background="none" color="#d2a9fd" left-icon="volume-o">{{site_bulletin}}</van-notice-bar>
		<div class="swiper-box">
			<van-loading class="vant-load" v-show="loadingShow" color="#000000" size="60" />

			<swiper v-show="loadingShow == false" :options="swiperOption" dir="rtl">
				<swiper-slide class="swiper-slide" v-for="(item,index) in carouselArr" :key="index">
					<a :href="item | RouteLink">
						<div class="swiper-item" :class="item.lv | LvGuoLv" dir="ltr">
							<span class="profit_ratio" v-show="item.profit_ratio != null">+{{item.profit_ratio}}</span>
							<div class="batlle" v-show="item.type == 3"><img src="@/assets/images/NavHeader/battle.png" alt=""></div>
							<div class="left">
								<div class="pic" :style="{'background-image': 'url('+item.lv_bg_image+')'}">
									<img :src="item.cover" alt="">
								</div>
								<div class="data">
										<p dir="ltr">{{item.box_name}}</p>
																			
									<span dir="ltr">{{item.name | GuoLvOne}}</span>
								</div>
							</div>
							<div class="right">
								<img :src="item.user.avatar" alt="">
								<p>{{item.user.name}}</p>
							</div>
						</div>
					</a>
				</swiper-slide>
			</swiper>
		</div>
	</div>
</template>

<script>
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'
	import {
		UnpackingRecord,
		Info
	} from '@/network/api.js'
	import yellow_case from '@/assets/images/PublicImg/yellow-case.png'
	import red_case from '@/assets/images/PublicImg/red-case.png'
	import violet_case from '@/assets/images/PublicImg/violet-case.png'
	import blue_case from '@/assets/images/PublicImg/blue-case.png'
	import grey_case from '@/assets/images/PublicImg/grey-case.png'
	export default {
		name: 'SwiperBox',
		data() {
			return {
				carouselArr: [],

				loadingShow: true,

				swiperOption: {
					// 自动轮播
					autoplay: {
						delay: 3000,
						//当用户滑动图片后继续自动轮播
						disableOnInteraction: true,
					},
					simulateTouch: false,
					//开启循环模式
					loop: true,
					slidesPerView: 10,
					centeredSlides: true,
					centeredSlidesBounds: true,
				},
				site_bulletin:''
			}

		},

		filters: {
			LvGuoLv(val){
				if (val == '1') {
					return 'lv1'
				} else if (val == '2') {
					return 'lv2'
				} else if (val == '3') {
					return 'lv3'
				} else if (val == '4') {
					return 'lv4'
				}else if (val == '5') {
					return 'lv4'
				}
			},
			
			RouteLink(item) {
				// console.log(item)
				if (item.type == '3') {
					return '/BoxFight'
				} else {
					return '/OpeningBox?id=' + item.box_id
				}
			},

			filter2(val) {
				if (val == '黄') {
					return yellow_case
				} else if (val == '红') {
					return red_case
				} else if (val == '紫') {
					return violet_case
				} else if (val == '蓝') {
					return blue_case
				} else {
					return grey_case
				}
			},
			GuoLvOne(val) {
				return val.length > 12 ? val.substr(0, 10) + '...' : val
			}
		},

		created() {
			this.GetUnpackingRecord()
			this.TenSeconds()
			this.GetInfo()
		},
		
		
		methods: {
			//开奖记录
			GetUnpackingRecord() {
				UnpackingRecord().then((res) => {
					// console.log(res.data.data.historylist)
					this.carouselArr = res.data.data.historylist

				})
			},
			TenSeconds() {
				setInterval(() => {
					if(this.$route.name!='OpeningBox'){
						this.GetUnpackingRecord()
					}
				}, 10000)
			},
			
			GetInfo(){
				Info().then((res) => {
					// console.log(res.data.data)
					this.site_bulletin = res.data.data.site_bulletin
				})
			}

		},



		watch: {
			carouselArr: {
				deep: true,
				handler: function(newV, oldV) {
					this.loadingShow = false
					// console.log('watch中：', newV)
					this.carouselArr = newV
				}
			}
		},


		components: {
			Swiper,
			SwiperSlide
		}
	}
</script>

<style lang="scss">
	#SwiperBox {
		width: 100%;
		height: 1rem;
		position: relative;
		z-index: 10;
		margin-top: .8rem;
		padding-top: .2rem;
		
		.swiper-box {
			width: 100%;
			height: 100%;
			position: relative;

			.vant-load {
				text-align: center;
				line-height: 1.4rem;
			}


			.swiper-slide {
				width: 3rem !important;
				height: 1rem;
				padding: 0 0.1rem;
				box-sizing: border-box;

				.swiper-item {
					width: 100%;
					height: .8rem;
					cursor: pointer;
					position: relative;
					padding: .05rem;
					box-sizing: border-box;
					overflow: hidden;
					display: flex;
					color: #fff;
					
					// &.lv1{
					// 	background: url(../../../assets/images/NavHeader/LV1.png) no-repeat center;
					// 	background-size: 100% 100%;
					// }
					// &.lv2{
					// 	background: url(../../../assets/images/NavHeader/LV2.png) no-repeat center;
					// 	background-size: 100% 100%;
					// }
					// &.lv3{
					// 	background: url(../../../assets/images/NavHeader/LV3.png) no-repeat center;
					// 	background-size: 100% 100%;
					// }
					// &.lv4{
					// 	background: url(../../../assets/images/NavHeader/LV4.png) no-repeat center;
					// 	background-size: 100% 100%;
					// }
					background-color: #5f4d91;

					&:hover{
						background: linear-gradient(180deg, #5f4d91,#aa6ffc);
					}
					
					.batlle{
						width: 20px;
						position: absolute;
						right: 5px;
						top: 5px;
						img{
							width: 100%;
						}
					}
					
					.profit_ratio{
						width: 50px;
						position: absolute;
						right: 70px;
						top: 5px;
						font-size: 8px;
						color: rgb(255, 211, 34);
					}
					
					.left {
						
						height: 100%;
						display: flex;
						align-items: center;

						.pic {
							width: .8rem;
							height: .8rem;
							background-repeat: no-repeat;
							background-size: 70%;
							background-position: center;
							display: flex;
							justify-content: center;
							align-items: center;

							img {
								max-width: 70%;
								max-height: 70%;
							}
						}

						.data {
							width: 1rem;
							// background: red;
							height: .8rem;
							p {
								width: .8rem;
								font-size: .14rem;
								margin-top: .18rem;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								color: #d42d4f;
							}
							

							span {
								display: block;
								margin-top: .02rem;
								width: 100%;
								font-size: .12rem;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}
					}

					.right {
						width: 1rem;
						text-align: center;
						padding-top: .1rem;
						padding-right: .1rem;
						box-sizing: border-box;
						img {
							width: .3rem;
							height: .3rem;
							border-radius: 50%;
							border: 1px solid rgb(255, 211, 34);
							box-sizing: border-box;
						}
						p{
							font-size: .12rem;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
				}
			}
		}
	}
</style>
