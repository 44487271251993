
<template>
	<div id="NavHeader">
		<div class="nav">
			<div class="Nav_Left">
			<div class="logo" @click="jump('/')">
				<img src="@/assets/images/NavHeader/logo.png" alt="">
			</div>
			<div class="nav-box">
				<div class="item" @click="jump('/')" :class="{'active' : this.$route.path == '/Home'}">
					<p>首页</p>
					<i></i>
				</div>
				<div class="item" @click="jump('/Roll')" :class="{'active' : this.$route.path == '/Roll'}">
					<p>ROLL房</p>
					<i></i>
				</div>
				<div class="item" @click="jump('/Lucky')" :class="{'active' : this.$route.path == '/Lucky'}">
					<p>幸运开箱</p>
					<i></i>
				</div>
				<div class="item" @click="jump('/Replacement')" :class="{'active' : this.$route.path == '/Replacement'}">
					<p>饰品升级</p>
					<i></i>
				</div>
				<div class="item" @click="jump('/Activity')" :class="{'active' : this.$route.path == '/Activity'}">
					<p>福利箱</p><!-- <span><img src="@/assets/images/NavHeader/hot.png" alt=""></span> -->
					<i></i>
				</div>
				<div class="item" @click="jump('/BoxFight')" :class="{'active' : this.$route.path == '/BoxFight'}">
					<p>盲盒对战</p>
					<i></i>
				</div>

        <div class="item" @click="jump('/Shop')">
          <p>饰品商城</p>
          <i></i>
        </div>
				
				
				<!-- <div class="item" @click="jump('/RushPurchase')" :class="{'active' : this.$route.path == '/RushPurchase'}">
					<p>抢卡</p>
					<i></i>
				</div> -->
				<div class="item" @click="jump('/Recharge')" :class="{'active' : this.$route.path == '/Recharge'}">
					<p>在线充值</p>
					<i></i>
				</div>			
			</div>
			</div>
			<div class="personal">
				<div class="Sign" v-show="this.IndividualsShow == false">
					<div class="Sign-item" @click="PostRegisterShow">
						<p>注册</p>
					</div>
					<div class="Sign-item" @click="PostSignInShow">
						<p>登录</p>
					</div>
				</div>
				
				<div class="per" v-show="this.IndividualsShow == true">
					<p @click="jump('/Recharge')"><img src="@/assets/images/PublicImg/JinBi.png" alt="" style="width:40px;height:40px;margin-top:0.08rem;">{{user.bean}}</p>
					<p style="color: #ffd322;" @click="jump('/Extension')"><img src="@/assets/images/NavHeader/wan_vip.png" alt="">{{user.promotion_level}}</p>
					<div class="user" @click="jump('/User')">
						<img :src="user.avatar" alt="">
						<span>{{user.name}}</span>
					</div>
				</div>
			</div>
		</div>
		
		
		
		<swiper></swiper>
    <banner v-if="this.$route.path == '/Home'"></banner>
		<div class="XiangZi_Box" v-if="false">
			<div class="XiangZi_item" v-for="(item,index) in Box_Arr" :ket="index" @click="ShowNeiRong(item)">
				<div class="pic">
					<img :src="item.cover" alt="">
				</div>
				<span><img src="@/assets/images/PublicImg/JinBi.png" alt="">{{item.game_bean}}</span>
				<p>{{item.name}}</p>
			</div>
		</div>
<!-- 		<div class="data_info">
			<div class="data_info_item">
				<img src="@/assets/images/NavHeader/ZX.png" alt="">
				<p><span style="color:#00d5c6;">{{SuiJiNum}}</span>在线</p>
			</div>
			<div class="data_info_item">
				<img src="@/assets/images/NavHeader/YH.png" alt="">
				<p><span>{{info.user_number}}</span>用户</p>
			</div>
			<div class="data_info_item">
				<img src="@/assets/images/NavHeader/XZ.png" alt="">
				<p><span>{{info.open_box_number}}</span>武器箱</p>
			</div>
		</div> -->

		
		<!-- 注册弹框 -->
		<van-overlay :show="RegisterShow">
			<div class="Wrapper">
				<div class="out">
					<div class="Ren"></div>
					<div class="DZ-box" :style="W_BL | STY">
						<p>注册</p>
						<div style="text-align: center;color: #ffd322;margin-top:5px;" v-show="noSignin">微信授权登录成功，请先绑定手机号</div>
						<el-form :model="RegisterForm" status-icon :rules="RegisterRules" ref="RegisterForm" label-width="90px" class="demo-ruleForm">
							<el-form-item label="手机号" prop="email">
								<el-input v-model="RegisterForm.email"></el-input>
							</el-form-item>
							<el-form-item label="验证码" class="YZM-box" prop="verify">
								<el-input v-model="RegisterForm.verify"></el-input>
								<div class="YZM" @click="openCaptcha" v-show="!VerificationShow">发送验证码</div>
								<div class="DJS" v-show="VerificationShow">
									<div class="XXX">已发送</div>
									<van-count-down :time="time" class="CLR" format="ss" :auto-start="false" @finish="finish" ref="countDown" />
								</div>
							</el-form-item>
							<el-form-item label="密码" prop="pass">
								<el-input type="password" v-model="RegisterForm.pass"></el-input>
							</el-form-item>
							<el-form-item label="昵称" prop="name">
								<el-input v-model="RegisterForm.name"></el-input>
							</el-form-item>
							<el-form-item label="邀请码" style="display: none;">
								<el-input v-model="RegisterForm.invite"></el-input>
							</el-form-item>
							
							<div class="XY_box">
								<el-checkbox v-model="XYchecked">我接受《用户条款和隐私政策》</el-checkbox>
							</div>
							
							<el-form-item>
								<div class="btn-box">
									<div class="btn" @click="RegisterQX">取消</div>
									<div class="btn" @click="PostRegister">注册</div>
								</div>
							</el-form-item>
							<!-- <div class="Steam" @click="OpenWechatQrcode">
								<p>-其他方式-</p>
								<div class="img_box"></div>
							</div> -->
						</el-form>
					</div>
				</div>
			</div>
		</van-overlay>


		<!-- 登录弹框 -->
		<van-overlay :show="SignInShow">
			<div class="Wrapper">
				<div class="out">
					<div class="Ren">
						
					</div>
				<div class="DZ-box"  :style="W_BL | STY" style="padding-top: 20px;">
					<p>登录</p>
					<el-form :model="SignInForm" status-icon :rules="SignInRules" ref="SignInForm" label-width="90px" class="demo-ruleForm">
						<el-form-item prop="email" label="手机号">
							<el-input v-model="SignInForm.email"></el-input>
						</el-form-item>
						<el-form-item prop="pass" label="密码">
							<el-input type="password" v-model="SignInForm.pass"></el-input>
						</el-form-item>
						<div class="remember_box">
							<el-checkbox v-model="checked">记住密码</el-checkbox>
							<p @click="GetChangePassword">忘记密码</p>
						</div>
						<el-form-item>
							<div class="btn-box">
								<div class="btn" @click="SignInQX">取消</div>
								<div class="btn" @click="PostSignIn">登录</div>
							</div>
						</el-form-item>
						<!-- <div class="Steam" @click="OpenWechatQrcode">
							<p>-其他方式-</p>
							<div class="img_box"></div>
						</div> -->
					</el-form>
				</div>
				</div>
			</div>
		</van-overlay>
		
		<!-- 箱子遮罩层 -->
		<van-overlay :show="XZshow">
			<div class="wrapper">
				<div class="block">
					<div class="block-T">
						<p>{{Box_Name}}</p>
						<span @click="CloseXZshow">×</span>
					</div>
					<div class="Box-List">
						<div class="Box-List-item" v-for="(item,index) in JiangPin_Arr" :key="index">
							<div class="pic">
								<div class="zhao">
									<img :src="item.lv_bg_image" alt="">
								</div>
								<img :src="item.cover" alt="">
							</div>
							<div class="name">{{item.name | GuoLv}}</div>
							<div class="money"><img src="@/assets/images/PublicImg/JinBi.png" alt=""><span>{{item.bean}}</span></div>
						</div>
					</div>
					<div class="boxbtn">
						<p @click="CloseXZshow">确定</p>
					</div>
				</div>
			</div>
		</van-overlay>
		
		<!-- 排行 -->
		<Rank v-show="true" />

		<!-- 图形验证码 -->
		<van-popup v-model="captchaShow" style="width:300px;padding:20px;border-radius:4px;font-size:13px;color:#333;">
			<label style="float:left;width:100%;padding-bottom:5px;font-size:16px;font-weight:bold;">请输入验证码</label>
			<input type="text" style="width:160px;padding:9px 5px;float:left;border:1px #999 solid;" placeholder="请输入验证码" v-model="RegisterForm.captcha">
			<img style="float:right;cursor:pointer;width:120px;height:36px;" :src="captchaUrl" @click="getCaptcha()">
			<span style="float:right;color:#000;cursor:pointer;padding:10px 0;" @click="getCaptcha()">看不清，换一张</span>
			<div style="width: 100%;float:left;">
				<span @click="GetYanZhengMa()" style="width:48%;height:36px;line-height:36px;float:left;background: #ffd322;border-radius: 4px;text-align: center;color: #000;cursor:pointer;">确定</span>
				<span @click="captchaShow=false" style="width:48%;height:36px;line-height:36px;float:right;background: #ffd322;border-radius: 4px;text-align: center;color: #000;cursor:pointer;">取消</span>
			</div>
		</van-popup>

	</div>
</template>

<script>
	import {
		Register,
		PostEmail,
		SignIn,
		PersonalInfo,
		Arena_Box_List,
		Info,
		captcha
	} from '@/network/api.js'
	import {
		mapState,
		mapMutations
	} from 'vuex'
	import Swiper from '@/components/common/NavHeader/Swiper.vue'
  import Banner from '@/components/common/NavHeader/Banner.vue'
	import Rank from '@/views/Rank/index.vue'
	export default {
		name: 'NavHeader',
		data() {
			return {
				W_BL:false,
				Box_Name:'',
				time: 60 * 1000,
				VerificationShow: false,
				Box_Arr: [],
				checked: true,
				XYchecked:true,
				JiangPin_Arr:[],
				
				Box_Arr_Show:false,
				
				XZshow:false,
				
				RegisterForm: {
					email: '',
					pass: '',
					name: '',
					verify: '',
					invite: '',
					agent: '',
					captcha: '',
					captchaKey: ''
				},

				captchaShow:false,
				captchaUrl:'',

				SignInForm: {
					email: '',
					pass: ''
				},
				SignInRules: {
					email: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
							message: '手机号格式错误',
							trigger: 'blur'
						}
					],
					pass: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							pattern: /^[A-Za-z0-9]{2,14}$/,
							message: '密码格式错误',
							trigger: 'blur'
						}
					]
				},

				RegisterRules: {
					email: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
							message: '手机号格式错误',
							trigger: 'blur'
						}
					],
					pass: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							pattern: /^[A-Za-z0-9]{2,14}$/,
							message: '密码格式错误',
							trigger: 'blur'
						}
					],
					name: [{
							required: true,
							message: '请输入昵称',
							trigger: 'blur'
						},
						{
							pattern: /^[\u4E00-\u9FA5A-Za-z0-9]{2,14}$/,
							message: '昵称格式错误',
							trigger: 'blur'
						}
					],
					verify: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}]
				},
				SuiJiNum:0,
				info:{},
				wechatUserInfo:sessionStorage.wechatUserInfo?JSON.parse(sessionStorage.wechatUserInfo):{'nickname':'','openid':'','unionid':'','headimgurl':''},
				noSignin:false
			}
		},

		created() {
			
			// console.log(this.$route)
			if(window.screen.width < 750){
				this.W_BL = true
			}else{
				this.W_BL = false
			}
			
			if(this.$route.path == '/BoxFight' || this.$route.path == '/vsbox' || this.$route.path == '/CreateAConfrontation'){
				this.Box_Arr_Show = true
			}
			sessionStorage.invite = this.getQueryString('ref')!=null?this.getQueryString('ref'):''
			sessionStorage.agent = this.getQueryString('agent')!=null?this.getQueryString('agent'):''
			this.RegisterForm.agent = sessionStorage.agent
			this.RegisterForm.invite = sessionStorage.invite
			this.RegisterForm.name = this.wechatUserInfo.nickname
			this.PanDuanDengLu()
			this.Get_Arena_Box_List()
			this.GetInfo()
			this.getCaptcha()
			window.addEventListener('message',this.closeWrchatQrcode, true)
		},
		destroyed(){
			window.removeEventListener('message', this.closeWrchatQrcode, true)
		},
		
		
		filters:{
			GuoLv(str){
				return str.length > 12 ? str.substr(0,10) + '...' : str
			},
			STY(val){
				// console.log(val)
				if(val == true){
					return 'width:80%;'
				}else{
					return 'width:5rem;'
				}
			}
		},
		
		activated() {

		},
		
		
		
		methods: {

			//微信登录成功
			closeWrchatQrcode(e){
				const _userInfo = e.data
				if(_userInfo['code']==1){
					sessionStorage.wechatUserInfo = JSON.stringify(_userInfo)
					this.wechatUserInfo = _userInfo
					this.RegisterForm.name = this.wechatUserInfo.nickname
					this.GetSignInShow(false)
					this.EmptySignInForm()
					this.GetRegisterShow(true)
					this.EmptyForm()
					this.noSignin=true
				}else if(_userInfo['code']==200){
					if (this.checked == true) {
						localStorage.setItem('token', 'Bearer' + ' ' + _userInfo.data.access_token)
						sessionStorage.setItem('token', 'Bearer' + ' ' + _userInfo.data.access_token)
					} else {
						sessionStorage.setItem('token', 'Bearer' + ' ' + _userInfo.data.access_token)
					}
					this.GetPersonalInfo()
					this.GetRegisterShow(false)
					this.EmptyForm()
					this.GetSignInShow(false)
					this.EmptySignInForm()
					this.noSignin=false
				}
			},

			//打开微信登录二维码
			OpenWechatQrcode(){
				const _open = process.env.VUE_APP_BASE_URL
				const _top = 200
				const _left = document.body.clientWidth/2-250
				window.open(_open+'wechatLoginQrcode','_blank','status=no,location=no,menubar=no,resizable=no,scrollbars=no,toolbar=no,width=500,height=500,top='+_top+',left='+_left);
			},

			//获取浏览器参数
			getQueryString(name) {
				let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
				let r = window.location.search.substr(1).match(reg);
				if (r != null) {
					return unescape(r[2]);
				};
				return null;
			},

			//初次进入判断是否记住登录
			PanDuanDengLu() {
				if (sessionStorage.getItem('token')) {
					this.GetPersonalInfo()
				} else {
					if (localStorage.getItem('token')) {
						sessionStorage.setItem('token', localStorage.getItem('token'))
						this.GetPersonalInfo()
					} else {
						this.GetIndividualsShow(false)
					}
				}

			},
			
			//站点信息
			GetInfo(){
				Info().then((res) => {
					this.info = res.data.data
					// console.log(res.data.data)
					localStorage.setItem('static_file_url',res.data.data.static_file_url)
					this.GetSuiJi()
				})
			},
			
			GetSuiJi(){
				this.SuiJiNum = Math.floor(Math.random() * (50 - Number(this.info.user_number))) + Number(this.info.user_number)
			},
			
			
			CloseXZshow(){
				this.XZshow = false
			},
			
			//暂未开放
			WuKaiFang(){
				this.$message({
					message: '暂未开放,敬请期待!',
					type: 'error'
				})
			},
			
			//查看箱子内容
			ShowNeiRong(item){
				// console.log(item)
				this.JiangPin_Arr = item.box_awards
				this.Box_Name = item.name
				this.XZshow = true
				
			},
			
			//获取宝箱列表
			Get_Arena_Box_List() {
				Arena_Box_List().then((res) => {
					// console.log(res)
					this.Box_Arr = res.data.data
				})
			},

			//路由跳转
			jump(str) {
				this.$router.push({
					path: str
				}).catch(err => {
					this.$router.go(0)
				})
			},

			//修改密码
			GetChangePassword() {
				this.$router.push('/ChangePassword')
				this.GetSignInShow(false)
			},

			//注册
			PostRegisterShow() {
				this.GetRegisterShow(true)
			},

			//注册操作
			PostRegister() {
				if(this.XYchecked){
					Register(this.RegisterForm.email, this.RegisterForm.pass, this.RegisterForm.name, this.RegisterForm.verify, this.RegisterForm.invite,this.wechatUserInfo.openid,this.wechatUserInfo.unionid,this.wechatUserInfo.headimgurl, this.RegisterForm.agent
					).then((res) => {
						// console.log(res)
						if (res.data.code == 200) {
							this.$message({
								message: '注册成功',
								type: 'success'
							})
							if (this.checked == true) {
								localStorage.setItem('token', 'Bearer' + ' ' + res.data.data.access_token)
								sessionStorage.setItem('token', 'Bearer' + ' ' + res.data.data.access_token)
							} else {
								sessionStorage.setItem('token', 'Bearer' + ' ' + res.data.data.access_token)
							}
							this.GetPersonalInfo()
							this.GetRegisterShow(false)
							this.EmptyForm()
						} else {
							this.$message({
								message: res.data.message,
								type: 'error'
							})
						}
					})
				}else{
					this.$message({
						message:'请先勾选用户协议',
						type: 'error'
					})
				}
				
			},

			//图形验证框
			openCaptcha(){
				if (this.RegisterForm.email) {
					this.captchaShow = true
					this.getCaptcha()
				}else{
					this.$message({
						message: '请输入手机号',
						type: 'error'
					})
				}
			},

			//获取图形验证器
			getCaptcha(){
				captcha().then((res) => {
					this.captchaUrl = res.data.url.img
					this.RegisterForm.captchaKey = res.data.url.key
				})
			},

			//发送手机号验证码
			GetYanZhengMa() {
				if(this.RegisterForm.captcha==''){
					this.$message({
						message: '请输入验证码',
						type: 'error'
					})
					return;
				}
				if (this.RegisterForm.email) {
					this.VerificationShow = true
					PostEmail(this.RegisterForm.email,this.RegisterForm.captcha,this.RegisterForm.captchaKey, '1').then((res) => {
						if (res.data.code == 200) {
							this.$message({
								message: '发送成功',
								type: 'success'
							})
							this.$refs.countDown.start();
							this.captchaShow = false
						} else {
							this.$message({
								message: res.data.message,
								type: 'error'
							})
							this.finish()
						}
					}).catch(() => {
						this.finish()
						this.getCaptcha()
						this.RegisterForm.captcha = ''
					})
				} else {
					this.$message({
						message: '请输入手机号',
						type: 'error'
					})
				}

			},

			finish() {
				//重置倒计时
				this.VerificationShow = false
				this.$refs.countDown.reset();
			},

			//取消注册
			RegisterQX() {
				this.GetRegisterShow(false)
				this.EmptyForm()
			},


			//登录
			PostSignInShow() {
				this.GetSignInShow(true)
			},


			//登录操作
			PostSignIn() {
				SignIn(this.SignInForm.email, this.SignInForm.pass).then((res) => {
					// console.log(res)
					if (res.data.code == 200) {
						this.$message({
							message: '登录成功',
							type: 'success'
						})
						if (this.checked == true) {
							localStorage.setItem('token', 'Bearer' + ' ' + res.data.data.access_token)
							sessionStorage.setItem('token', 'Bearer' + ' ' + res.data.data.access_token)
						} else {
							sessionStorage.setItem('token', 'Bearer' + ' ' + res.data.data.access_token)
						}
						this.GetPersonalInfo()
						this.GetSignInShow(false)
						this.EmptySignInForm()
					} else {
						this.$message({
							message: res.data.message,
							type: 'error'
						})
					}
				})

			},

			//个人信息
			GetPersonalInfo() {
				PersonalInfo().then((res) => {
					// console.log(res.data.message)
					this.User(res.data.message)
					this.GetIndividualsShow(true)
					// console.log(res.data.message)
				})
			},

			//取消登录
			SignInQX() {
				this.EmptySignInForm()
				this.GetSignInShow(false)
			},


			//个人下拉菜单
			handleCommand(command) {
				switch (command) {
					case 'out':
						this.SignOut()
						break;
					default:
						this.$router.push(command)
				}

			},



			//退出
			SignOut() {
				this.GetIndividualsShow(false)
				this.Leave()
			},

			//清空注册表单
			EmptyForm() {
				this.RegisterForm.email = ''
				this.RegisterForm.verify = ''
				this.RegisterForm.pass = ''
				this.RegisterForm.name = this.wechatUserInfo.nickname
				this.RegisterForm.invite = sessionStorage.invite
				this.noSignin=false
			},

			//清空登录表单
			EmptySignInForm() {
				this.SignInForm.email = ''
				this.SignInForm.pass = ''
				this.noSignin=false
			},

			//路由跳转充值
			ToRecharge() {
				this.$router.push('/Recharge')
			},

			...mapMutations([
				'User',
				'GetIndividualsShow',
				'Leave',
				'GetRegisterShow',
				'GetSignInShow'
			])

		},

		computed: {
			...mapState([
				'user',
				'IndividualsShow',
				'RegisterShow',
				'SignInShow'
			])
		},

		watch: {
			Email_Name(val) {
				this.Email_Name = val
			},

			$route(to, from) {
				// console.log(to.path)
				if(to.path == '/BoxFight' || to.path == '/vsbox' || to.path == '/CreateAConfrontation'){
					this.Box_Arr_Show = true
				}else{
					this.Box_Arr_Show = false
				}
			}
		},

		components: {
			Swiper,
      Banner,
			Rank
		}
	}
</script>

<style lang="scss">
	#NavHeader {
		width: 100%;
		position: relative;
		.XiangZi_Box {
			width: 100%;
			display: flex;
			.XiangZi_item {
				width: 1.92rem;
				height: 2.3rem;
				background: rgba(25, 25, 25, 0.6);
				border: 1px solid #2b2b2b;
				margin-left: -1px;
				cursor: pointer;
				text-align: center;
				position: relative;
				box-sizing: border-box;
				.pic {
					width: 1.6rem;
					height: 1.6rem;
					margin: 0 auto;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						vertical-align: middle;
						max-width: 80%;
						max-height: 80%;
					}
				
				}		
				span{
					img{
						width: 0.2rem;
						height: 0.2rem;
						vertical-align: middle;
					}
					font-size: 0.14rem;
					color: #ffd300;
				}
				p {
					margin-top: 0.08rem;
					font-size: 0.14rem;
				}
				&:hover{
					border-color: #ffd300;
					z-index: 5;
					p{
						color: #ffd300;
					}
				}
			}
		}



		.nav {
			width: 100%;
			height:.9rem;
			padding: 0 0.4rem;
			position: fixed;
			left: 0;
			top:0;
			z-index: 99;
			box-sizing: border-box;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-sizing: border-box;
			background-color:rgba($color: #000, $alpha: .3);
			.Nav_Left{
				width: 70%;
				display: flex;
				align-items: center;
				.logo {
					width: 2rem;
					cursor: pointer;
					margin-right: 1rem;
				
					img {
						width: 3rem;
					}
				}
				
				.nav-box {
					width: 10rem;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					
					.item {
						width: 2.5rem;
						height: .5rem;
						line-height: .5rem;
						border-radius:5px;
						box-sizing: border-box;
						text-align: center;
						cursor: pointer;
						position: relative;
						margin-right: 0.2rem;
						position: relative;
						transition:all 0.15s ease;
						p {
							width: 100%;
							color: #fff;
							font-size: 0.16rem;
						}
				
						i {
							display: block;
							width: 20%;
							height: 0.04rem;
							position: absolute;
							background: #fff;
							left: 50%;
							bottom: 0;
							transform: translateX(-50%);
							opacity: 0;
						}
						&:hover{
							background: rgba($color: #fff, $alpha: .1);
						}
						&.active{
							background: rgba($color: #fff, $alpha: .1);
							i{
								opacity: 1;
							}
						}
					}
				}
			}
	
		
			.personal {
				width: 5rem;
				height:100%;
				.per {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: flex-end;
					cursor: pointer;
					p{
						width: 1.28rem;
						height: 100%;
						text-align: center;
						color: #ffd728;
						font-size: .18rem;
						font-weight: bold;
						border-left: 1px solid rgba($color: #fff, $alpha: .08);
						margin: 0;
						
						img{
							display: block;
							margin: .2rem auto .05rem;
							width: 24px;
							height: 24px;
						}
					}
					.user{
						width: 1.28rem;
						height: 100%;
						border-left: 1px solid rgba($color: #fff, $alpha: .08);
						text-align: center;
						img{
							display: block;
							width: .5rem;
							height:.5rem;
							border-radius: 50%;
							border: 1px solid #ffba2e;
							margin: .05rem auto;
						}
						span{
							font-size: .14rem;
						}
					}
					
				}
			
				.Sign {
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					.Sign-item {
						width: .7rem;
						height: 0.3rem;
						cursor: pointer;
						display: flex;
						justify-content: center;
						align-items: center;
						text-align: center;
						line-height: 0.3rem;
						color: #fc63cf;
						border:1px #fc63cf solid;
						
						&:first-child{
							margin-right:0.08rem;
							
						}

						&:hover{
							color:#fff;
							background-color:#fc63cf;
						}
			
						p {
							font-size: 0.16rem;
						}
					}
				}
			}
		}
		.data_info{
			width: 100%;
			padding: 0 0.6rem 0.2rem;
			box-sizing: border-box;
			height: 0.6rem;
			display: flex;
			align-items: center;
			.data_info_item{
				display: flex;
				align-items: center;
				height: 0.6rem;
				margin-right: 0.2rem;
				img{
					height: 0.4rem;
					margin-right: 0.05rem;
				}
				p{
					font-size: 0.22rem;
					color: #a99bc5;
					span{
						color: #ea4391;
						margin-right: 0.04rem;
					}
				}
			}
		}

		
		.Wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			position: relative;
			.out{
				display: flex;
				width: 8rem;
				.Ren{
					flex: 1;
					background: url(../../../assets/images/public/back_dl.jpg) no-repeat;
					background-size: cover;
				}
			}
			.DZ-box {
				width: 4.5rem !important;
				background: #20222b;
				position: relative;
				// &::after{
				// 	display: block;
				// 	content: "";
				// 	width: 3rem;
				// 	height: 100%;
				// 	position: absolute;
				// 	left: -2.5rem;
				// 	top: 0;
				// 	background: url(../../../assets/images/public/name.webp) no-repeat center;
				// 	background-size: contain;
				// }
			
				p {
					font-size: 0.22rem;
					text-align: center;
					margin-top: 0.2rem;
				}
			
				.Steam {
					width: 100%;
					padding-left: 0.9rem;
					text-align: center;
					box-sizing: border-box;
					margin-bottom: 0.2rem;
			
					p {
						font-size: 0.14rem;
						margin: 0;
					}
			
					.img_box {
						width: 0.4rem;
						height: 0.4rem;
						margin: 0.2rem auto 0;
						background: url(../../../assets/images/NavHeader/wechatClick.png) no-repeat center;
						background-size: cover;
						cursor: pointer;
			
						&:hover {
							background: url(../../../assets/images/NavHeader/wechat.png) no-repeat center;
							background-size: cover;
						}
					}
				}
			
				.el-form {
					width: 90%;
					margin-top: 0.2rem;
			
					.remember_box {
						width: 100%;
						height: 0.2rem;
						display: flex;
						padding-left: 0.9rem;
						align-items: center;
						justify-content: space-between;
						box-sizing: border-box;
						margin-bottom: 0.2rem;
			
						p {
							line-height: 0;
							margin-top: 0;
							font-size: 0.14rem;
							cursor: pointer;
			
							&:hover {
								color: red;
							}
						}
			
						.el-checkbox__input.is-checked+.el-checkbox__label {
							color: #ffd322;
						}
			
						.el-checkbox__input.is-checked .el-checkbox__inner,
						.el-checkbox__input.is-indeterminate .el-checkbox__inner {
							border-color: #ffd322;
							background: #ffd322;
						}
			
						.el-checkbox {
							color: #000;
							font-size: 0.14rem;
						}
					}
			
					.el-form-item__label {
						color: #fff;
						font-size: 0.14rem;
					}
			
					.el-input {
						.el-input__inner {
							background: #fff;
							border: none;
							color: #000;
						}
					}
				}
			
				.YZM-box {
					.el-form-item__content {
						display: flex;
						align-items: center;
			
						.el-input {
							width: 70%;
						}
			
						.YZM {
							width: 1rem;
							height: 0.38rem;
							margin-left: 0.3rem;
							font-size: 0.14rem;
							line-height: 0.38rem;
							text-align: center;
							background: #ffd322;
							cursor: pointer;
							color: #000;
						}
			
						.DJS {
							width: 1rem;
							height: 0.38rem;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-left: 0.3rem;
							background: #ffd322;
			
							.XXX {
								height: 0.38rem;
								font-size: 0.14rem;
								margin-right: 0.05rem;
								box-sizing: border-box;
								margin-bottom: 0.02rem;
							}
			
							.CLR {
								font-size: 0.14rem;
								color: #000;
							}
						}
					}
				}
			
				.btn-box {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
			
					.btn {
						width: 50%;
						height: 0.4rem;
						background: #ffd322;
						color: #000;
						text-align: center;
						line-height: 0.4rem;
						margin: 0 0.1rem;
						border-radius: 0.04rem;
						cursor: pointer;
					}
				}
				
				.XY_box{
					width: 100%;
					display: flex;
					justify-content: center;
					margin-bottom: 20px;
					
					.el-checkbox__label{
						color:#ffd322 ;
					}
					.el-checkbox__input.is-checked .el-checkbox__inner,
					.el-checkbox__input.is-indeterminate .el-checkbox__inner {
						border-color: #ffd322;
						background: #ffd322;
					}
				}
			}
		}
	
		.wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
		
		.block {
			width: 8rem;
			height: 6.7rem;
			background-color: #212529;
			border-radius: 0.06rem;
			padding: 0.2rem;
			box-sizing: border-box;
		
			.block-T {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
		
				p {
					font-size: 0.24rem;
					color: #fff;
				}
		
				span {
					font-size: 0.28rem;
					color: rgba($color: #ffffff, $alpha: .6);
					cursor: pointer;
				}
			}
		
			.Box-List {
				width: 100%;
				height: 5rem;
				margin: 0.2rem 0 0.4rem;
				overflow: auto;
				display: flex;
				flex-wrap: wrap;
		
				.Box-List-item {
					width: 1.8rem;
					height: 2.3rem;
					position: relative;
					background: rgba(0, 0, 0, .2);
					border-radius: 0.04rem;
					margin-right: 0.1rem;
					margin-bottom: 0.1rem;
					cursor: pointer;
					overflow: hidden;
		
					&:nth-child(4n) {
						margin-right: 0;
					}
		
					&:hover {
						.ceng {
							opacity: 1;
						}
					}
		
					.pic {
						width: 1.8rem;
						height: 1.5rem;
						margin: 0.1rem auto 0;
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;
		
						.zhao {
							width: 100%;
							height: 100%;
							position: absolute;
							left: 0;
							top: 0;
							display: flex;
							justify-content: center;
							align-items: center;
		
							img {
								max-width: 100%;
								max-height: 100%;
							}
						}
		
						img {
							max-width: 100%;
							max-height: 100%;
							position: relative;
							z-index: 10;
						}
					}
		
					.name {
						width: 100%;
						text-align: center;
						color: #9e9e9e;
						font-size: 0.14rem;
					}
		
					.money {
						width: 100%;
						margin-top: 0.1rem;
						display: flex;
						justify-content: center;
						align-items: center;
		
						img {
							width: 0.2rem;
							height: 0.2rem;
							vertical-align: middle;
						}
		
						span {
							font-size: 0.14rem;
							color: #ffd300;
						}
					}
				}
			}
		
			.Box-List::-webkit-scrollbar {
				/*滚动条整体样式*/
				width: 4px;
				/*高宽分别对应横竖滚动条的尺寸*/
				height: 4px;
				scrollbar-arrow-color: red;
		
			}
		
			.Box-List::-webkit-scrollbar-thumb {
				/*滚动条里面小方块*/
				border-radius: 5px;
				-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
				background: rgba(0, 0, 0, 0.6);
				scrollbar-arrow-color: red;
			}
		
			.Box-List::-webkit-scrollbar-track {
				/*滚动条里面轨道*/
				-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
				border-radius: 0;
				background: rgba(0, 0, 0, 0.1);
			}
		
			.boxbtn {
				width: 100%;
				height: 0.35rem;
				display: flex;
				justify-content: flex-end;
		
				p {
					width: .7rem;
					height: .35rem;
					background: red;
					font-size: 0.14rem;
					color: #fff;
					text-align: center;
					line-height: 0.35rem;
					cursor: pointer;
				}
		
			}
		}
	}
</style>
